import _objectSpread from "D:\\Projects\\118_meijia\\frontend\\node_modules\\@babel\\runtime\\helpers\\esm\\objectSpread2.js";
import "core-js/modules/es.function.name.js";
import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue";
var _hoisted_1 = {
  class: "bar-textarea"
};
var _hoisted_2 = ["value"];
import { useField, ErrorMessage } from 'vee-validate';
export default {
  __name: 'BarTextArea',
  props: {
    modelValue: String,
    placeholder: String,
    name: String,
    inputBind: Object
  },
  setup: function setup(__props) {
    // validate
    var _useField = useField(name),
        errorMessage = _useField.errorMessage,
        thisName = _useField.name,
        value = _useField.value,
        meta = _useField.meta,
        errors = _useField.errors,
        resetField = _useField.resetField,
        validate = _useField.validate,
        handleChange = _useField.handleChange,
        handleBlur = _useField.handleBlur,
        setValidationState = _useField.setValidationState,
        checked = _useField.checked;

    return function (_ctx, _cache) {
      var _component_FormItem = _resolveComponent("FormItem");

      return _openBlock(), _createBlock(_component_FormItem, null, _createSlots({
        default: _withCtx(function () {
          return [_createElementVNode("div", _hoisted_1, [_createElementVNode("textarea", _mergeProps({
            name: "",
            id: "",
            cols: "30",
            rows: "3",
            value: __props.modelValue,
            onInput: _cache[0] || (_cache[0] = function ($event) {
              return _ctx.$emit('update:modelValue', $event.target.value);
            })
          }, _objectSpread({
            placeholder: __props.placeholder,
            name: __props.name
          }, __props.inputBind)), null, 16, _hoisted_2)])];
        }),
        _: 2
      }, [_unref(errorMessage) ? {
        name: "err-msg",
        fn: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_unref(errorMessage)), 1)];
        }),
        key: "0"
      } : undefined]), 1024);
    };
  }
};