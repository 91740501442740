/* unplugin-vue-components disabled */import __unplugin_components_4 from 'D:/Projects/118_meijia/frontend/src/components/PrintPage.vue';
import __unplugin_components_3 from 'D:/Projects/118_meijia/frontend/src/components/EditEventPopup.vue';
import __unplugin_components_2 from 'D:/Projects/118_meijia/frontend/src/components/AddEventPopup.vue';
import __unplugin_components_1 from 'D:/Projects/118_meijia/frontend/src/components/AppGlobalSpinner.vue';
import __unplugin_components_0 from 'D:/Projects/118_meijia/frontend/src/components/AppSwalCustomDialog.vue';
import _objectSpread from "D:\\Projects\\118_meijia\\frontend\\node_modules\\@babel\\runtime\\helpers\\esm\\objectSpread2.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.keys.js";
import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import { getScrollbarWidth } from '@/js/utils';
import { layout } from '@/plugin/layout';
import storage, { TEMP_MESSAGE_STORAGE_NAME } from '@/js/storage';
import { debounce } from 'lodash';
import moment from 'moment';
export default {
  __name: 'App',
  setup: function setup(__props) {
    var refMsgBox = ref(null);
    var cData = reactive({
      modalShow: false
    });
    var router = useRouter();
    var route = useRoute();
    var store = useStore(); // 開啟新增事件彈跳視窗

    function openAddEventPopup(event) {
      // 設定預設值
      store.commit('setData', {
        addPopupShow: true,
        addPopupTarget: '#add-work',
        addPopupEventData: event,
        addPopupConfig: {
          backdrop: true
        }
      });
    } // 開啟編輯事件彈跳視窗


    function openEditEventPopup(event, options) {
      var options = options || {};
      var popupConfig = options.popupConfig || {};
      store.commit('setData', {
        editPopupShow: true,
        editPopupTarget: options.target,
        editPopupEventData: event,
        editPopupConfig: _objectSpread({
          backdrop: true
        }, popupConfig)
      });
    }

    if (store.state.auth.isLogined) {
      store.dispatch('employee/getEmployee');
      store.dispatch('common/getHints');
      store.dispatch('common/getRooms');
    } // 品牌是舊資料就登出


    if (!Array.isArray(store.state.auth.branches)) {
      store.dispatch('auth/logout');
      window.location = '/';
    } // 日期變化=> 依照日周月判斷是否需要重新取得預約資料


    watch(function () {
      return store.getters['calendar/selectedDateFormat'];
    }, debounce(function (val, oldValue) {
      var calendarType = store.state.calendar.calendarType;

      if (calendarType === 'day') {
        // 更新日曆資料
        store.dispatch('calendar/getEvents');
      } else if (calendarType === 'week') {
        var newDate = moment(val, 'YYYY/MM/DD').add(-1, 'days');
        var oldDate = moment(oldValue, 'YYYY/MM/DD').add(-1, 'days');

        if (!moment(newDate).isSame(oldDate, 'week')) {
          // 更新日曆資料
          store.dispatch('calendar/getEvents');
        }
      } else if (calendarType === 'month') {
        var newDate = moment(val, 'YYYY/MM/DD');
        var oldDate = moment(oldValue, 'YYYY/MM/DD');

        if (!moment(newDate).isSame(oldDate, 'month')) {
          // 更新日曆資料
          store.dispatch('calendar/getEvents');
        }
      }
    }, 300));
    onMounted(function () {
      // cData.modalShow = true
      $(window).on('scroll', function () {
        if (window.pageYOffset > 20) {
          $('body').addClass('is-scrolling');
        } else {
          $('body').removeClass('is-scrolling');
        }
      });
      $('body').addClass('is-ready'); // 設定環境變數

      document.documentElement.style.setProperty('--scrollbar-width', 0 + 'px'); // 是否有訊息

      storage.getItem(TEMP_MESSAGE_STORAGE_NAME).then(function (res) {
        storage.removeItem(TEMP_MESSAGE_STORAGE_NAME);

        if (res) {
          appSwalCustomDialog.open(res);
        }
      });
    });
    provide('openAddEventPopup', openAddEventPopup);
    provide('openEditEventPopup', openEditEventPopup);
    var isNeedToUpdate = ref(false);

    var updateNeedToUpdate = function updateNeedToUpdate(val) {
      isNeedToUpdate.value = val;
    };

    provide('editEventStatus', {
      isNeedToUpdate: isNeedToUpdate,
      updateNeedToUpdate: updateNeedToUpdate
    });
    var keepAliveList = ref(['CalendarPage']);
    var routerName = computed(function () {
      return router.currentRoute.value.name;
    }); // 如果是公告欄系列頁面，則加入keep-alive

    var announcementKeepAlive = computed(function () {
      return String(routerName.value).includes('announcement');
    });
    watch(announcementKeepAlive, function (val) {
      if (val) {
        keepAliveList.value.push('Announcement');
      } else {
        keepAliveList.value = keepAliveList.value.filter(function (item) {
          return item !== 'Announcement';
        });
      }
    });
    return function (_ctx, _cache) {
      var _component_router_view = _resolveComponent("router-view");

      var _component_AppSwalCustomDialog = __unplugin_components_0;

      var _component_AppGlobalSpinner = __unplugin_components_1;

      var _component_AddEventPopup = __unplugin_components_2;

      var _component_EditEventPopup = __unplugin_components_3;

      var _component_PrintPage = __unplugin_components_4;

      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", {
        class: _normalizeClass({
          'no-print': Object.keys(_unref(store).state.print.data).length
        })
      }, [(_openBlock(), _createBlock(_resolveDynamicComponent(_unref(layout).value), {
        class: "__layout"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_router_view, null, {
            default: _withCtx(function (_ref) {
              var Component = _ref.Component;
              return [_createVNode(_Transition, {
                name: "fade",
                mode: "out-in"
              }, {
                default: _withCtx(function () {
                  return [(_openBlock(), _createBlock(_KeepAlive, {
                    include: _unref(keepAliveList)
                  }, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))], 1032, ["include"]))];
                }),
                _: 2
              }, 1024)];
            }),
            _: 1
          })];
        }),
        _: 1
      })), _createVNode(_component_AppSwalCustomDialog), _createVNode(_component_AppGlobalSpinner), _createVNode(_component_AddEventPopup, _mergeProps(_unref(store).state.addPopupConfig, {
        show: _unref(store).state.addPopupShow,
        "onUpdate:show": _cache[0] || (_cache[0] = function ($event) {
          return _unref(store).state.addPopupShow = $event;
        }),
        target: _unref(store).state.addPopupTarget,
        data: _unref(store).state.addPopupEventData,
        onAddEvent: _cache[1] || (_cache[1] = function ($event) {
          return _ctx.$window.console.log('addEvent');
        }),
        onInit: _cache[2] || (_cache[2] = function ($event) {
          return _unref(store).state.addPopupInstance = $event;
        })
      }), null, 16, ["show", "target", "data"]), _createVNode(_component_EditEventPopup, _mergeProps(_unref(store).state.editPopupConfig, {
        show: _unref(store).state.editPopupShow,
        "onUpdate:show": _cache[3] || (_cache[3] = function ($event) {
          return _unref(store).state.editPopupShow = $event;
        }),
        target: _unref(store).state.editPopupTarget,
        data: _unref(store).state.editPopupEventData,
        onEventUpdate: _cache[4] || (_cache[4] = function ($event) {
          _ctx.$window.console.log('eventUpdate');

          updateNeedToUpdate(true);
        }),
        onEventRemove: _cache[5] || (_cache[5] = function ($event) {
          _ctx.$window.console.log('eventRemove');

          updateNeedToUpdate(true);
        })
      }), null, 16, ["show", "target", "data"])], 2), Object.keys(_unref(store).state.print.data).length ? (_openBlock(), _createBlock(_component_PrintPage, {
        key: 0
      })) : _createCommentVNode("", true)], 64);
    };
  }
};