/* unplugin-vue-components disabled */import __unplugin_components_1 from 'D:/Projects/118_meijia/frontend/src/components/form/FormItem.vue';
import __unplugin_components_0 from 'D:/Projects/118_meijia/frontend/src/components/form/UInput.vue';
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, toDisplayString as _toDisplayString, unref as _unref, createTextVNode as _createTextVNode, Fragment as _Fragment, createBlock as _createBlock } from "vue";
var _hoisted_1 = ["innerHTML"];
var _hoisted_2 = {
  class: "m-input_ui-input-wrap"
};
var _hoisted_3 = {
  key: 0,
  class: "m-input_end-word"
};
import { useField, ErrorMessage } from 'vee-validate';
export default {
  __name: 'MInput',
  props: {
    modelValue: null,
    inputBind: Object,
    password: Boolean,
    h: String,
    fz: String,
    radius: String,
    variant: String,
    tag: String,
    // FormItem
    label: String,
    icon: String,
    // other
    bg: String,
    // 原生
    placeholder: String,
    name: String,
    readonly: Boolean,
    disabled: Boolean,
    type: {
      type: String,
      default: 'text'
    },
    customError: String,
    required: Boolean,
    requiredString: String,
    endWord: String
  },
  setup: function setup(__props, _ref) {
    var expose = _ref.expose;
    var props = __props;
    /**
     * 
     */

    var errorMessage = ''; // prevent vue from showing false alert

    var refAccess = ref(null);
    var refRoot = '';
    var cData = reactive({
      passwordVisible: false
    });

    function toHidePwd() {
      cData.passwordVisible = false;
      refRoot.find('input').attr('type', 'password');
      refRoot.find('.icon-pwd-to-see').show();
      refRoot.find('.icon-pwd-to-hide').hide();
    }

    function toShowPwd() {
      cData.passwordVisible = true;
      refRoot.find('input').attr('type', 'text');
      refRoot.find('.icon-pwd-to-see').hide();
      refRoot.find('.icon-pwd-to-hide').show();
    }

    function togglePassword() {
      if (cData.passwordVisible) {
        toHidePwd();
      } else {
        toShowPwd();
      }
    }

    function update(evt) {
      console.log('evt', evt);
    }

    onMounted(function () {
      var refAccessEl = $(refAccess.value);
      refRoot = refAccessEl.closest('.m-input');

      if (props.password) {
        toHidePwd();
      }
    });

    if (props.name) {
      // validate
      var _useField = useField(props.name),
          errorMessage = _useField.errorMessage,
          thisName = _useField.name,
          value = _useField.value,
          meta = _useField.meta,
          errors = _useField.errors,
          resetField = _useField.resetField,
          validate = _useField.validate,
          handleChange = _useField.handleChange,
          handleBlur = _useField.handleBlur,
          setValidationState = _useField.setValidationState,
          checked = _useField.checked;
    }

    expose({
      refRoot: refRoot
    });
    return function (_ctx, _cache) {
      var _component_UInput = __unplugin_components_0;

      var _component_FormItem = __unplugin_components_1;

      return _openBlock(), _createBlock(_component_FormItem, {
        class: _normalizeClass(["m-input", [__props.icon ? 'm-input_append-icon' : '', __props.variant ? "m-input_".concat(__props.variant) : '', __props.tag == 'textarea' ? 'm-input_textarea' : '']])
      }, _createSlots({
        default: _withCtx(function () {
          return [_createElementVNode("div", {
            ref_key: "refAccess",
            ref: refAccess,
            style: {
              "position": "absolute"
            }
          }, null, 512), _createElementVNode("div", _hoisted_2, [_createVNode(_component_UInput, _mergeProps({
            "model-value": __props.modelValue
          }, {
            h: __props.h,
            bg: __props.bg,
            fz: __props.fz,
            name: __props.name,
            radius: __props.radius,
            placeholder: __props.placeholder,
            readonly: __props.readonly,
            disabled: __props.disabled,
            type: __props.type,
            tag: __props.tag,
            inputBind: __props.inputBind
          }, {
            onBlur: _cache[0] || (_cache[0] = function ($event) {
              return _ctx.$emit('blur');
            }),
            "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
              return _ctx.$emit('update:modelValue', $event);
            })
          }), _createSlots({
            _: 2
          }, [_ctx.$slots['append'] || __props.icon || __props.password ? {
            name: "append",
            fn: _withCtx(function () {
              return [_createElementVNode("i", {
                class: _normalizeClass(["icon", __props.icon])
              }, null, 2), __props.password ? (_openBlock(), _createElementBlock("i", {
                key: 0,
                class: "icon-pwd-to-see",
                onClick: togglePassword
              })) : _createCommentVNode("", true), __props.password ? (_openBlock(), _createElementBlock("i", {
                key: 1,
                class: "icon-pwd-to-hide",
                onClick: togglePassword
              })) : _createCommentVNode("", true)];
            }),
            key: "0"
          } : undefined]), 1040, ["model-value"]), __props.endWord ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(__props.endWord), 1)) : _createCommentVNode("", true)]), __props.customError ? (_openBlock(), _createElementBlock(_Fragment, {
            key: 0
          }, [_createTextVNode(_toDisplayString(__props.customError), 1)], 64)) : _createCommentVNode("", true)];
        }),
        _: 2
      }, [__props.label ? {
        name: "label",
        fn: _withCtx(function () {
          return [_createElementVNode("div", {
            innerHTML: __props.label + (__props.required ? "<span class='m-input__required m-input__required--string'>".concat(__props.requiredString || '*', "</span>") : '')
          }, null, 8, _hoisted_1)];
        }),
        key: "0"
      } : undefined, _unref(errorMessage) ? {
        name: "err-msg",
        fn: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_unref(errorMessage)), 1)];
        }),
        key: "1"
      } : undefined]), 1032, ["class"]);
    };
  }
};