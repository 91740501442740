/* unplugin-vue-components disabled */import __unplugin_components_1 from 'D:/Projects/118_meijia/frontend/src/components/ListIconItem.vue';
import __unplugin_components_0 from 'D:/Projects/118_meijia/frontend/src/components/MSwitch.vue';
import "core-js/modules/es.number.constructor.js";
import { renderSlot as _renderSlot, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export default {
  __name: 'ListIconSwitchItem',
  props: {
    modelValue: [Boolean, Number]
  },
  emits: ['update:modelValue'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var cData = reactive({
      modelValue: false
    });

    function onchange(evt) {
      emit('update:modelValue', evt);
    }

    watchEffect(function () {
      if (props.modelValue) {
        cData.modelValue = true;
      } else {
        cData.modelValue = false;
      }
    });
    return function (_ctx, _cache) {
      var _component_MSwitch = __unplugin_components_0;

      var _component_ListIconItem = __unplugin_components_1;

      return _openBlock(), _createBlock(_component_ListIconItem, {
        class: "list-icon-item-toggler"
      }, {
        prepend: _withCtx(function () {
          return [_renderSlot(_ctx.$slots, "prepend")];
        }),
        append: _withCtx(function () {
          return [_createVNode(_component_MSwitch, {
            modelValue: _unref(cData).modelValue,
            "onUpdate:modelValue": [_cache[0] || (_cache[0] = function ($event) {
              return _unref(cData).modelValue = $event;
            }), onchange]
          }, null, 8, ["modelValue"])];
        }),
        default: _withCtx(function () {
          return [_renderSlot(_ctx.$slots, "default")];
        }),
        _: 3
      });
    };
  }
};