import "D:\\Projects\\118_meijia\\frontend\\node_modules\\core-js\\modules\\es.array.iterator.js";
import "D:\\Projects\\118_meijia\\frontend\\node_modules\\core-js\\modules\\es.promise.js";
import "D:\\Projects\\118_meijia\\frontend\\node_modules\\core-js\\modules\\es.object.assign.js";
import "D:\\Projects\\118_meijia\\frontend\\node_modules\\core-js\\modules\\es.promise.finally.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import { registerDirectives } from './js/directives';
import Router from './js/routes';
import './js/jquery-extend'; //style

import BootstrapVue3 from 'bootstrap-vue-3';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
import '@/css/app.scss';
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/lara-light-indigo/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import { upperFirst, camelCase } from "lodash"; // validation

import { autoSetLocal } from '@/js/validations.js';
/**
 * unplugin-auto-import/webpack 套件在import vue的ref時跟template裡的ref衝突
 * @see https://github.com/antfu/unplugin-auto-import/issues/89#issuecomment-994469071
 * 
 * - 會出現ref is not defined
 * - 只有build後的版本才會發生
 */

import 'vue-global-api/ref'; // 定義全域可取用的components

function importAll(requireComponent) {
  requireComponent.keys().forEach(function (fileName) {
    var componentConfig = requireComponent(fileName);
    var componentName = upperFirst(camelCase(fileName.replace(/^\.\/(.*)\.\w+$/, "$1")));
    app.component(componentName, componentConfig.default || componentConfig);
  });
}

var app = createApp(App);
app.use(store); // global vars

app.config.globalProperties.$window = window; // app create後，註冊全域元件

importAll(require.context("@/layout", false, /[A-Z]\w+\.(vue|js)$/)); // vuex-perist store 資料從本地storage寫回vuex完成

store.restored.then(function () {
  // 測試環境
  if (process.env.NODE_ENV == 'development') {
    window.store = store;
    window.router = Router;
    window.mukiConst = mukiConst;
  } // App UI 主程式掛載前，將store資料準備好


  store.dispatch('init');
  app.use(Router);
  app.use(BootstrapVue3);
  app.use(PrimeVue, {
    locale: {
      emptyMessage: '尚無選項'
    }
  }); // 註冊directives

  registerDirectives(app);
  app.mount('#app');
  autoSetLocal();
});