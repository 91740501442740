/* unplugin-vue-components disabled */import _objectSpread from "D:\\Projects\\118_meijia\\frontend\\node_modules\\@babel\\runtime\\helpers\\esm\\objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.function.name.js";
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "u-input__prepend"
};
var _hoisted_2 = {
  key: 1,
  class: "u-input__append"
};
export default {
  __name: 'UInput',
  props: {
    modelValue: [String, Number],
    tag: {
      type: String,
      default: 'input'
    },
    inputBind: Object,
    h: String,
    fz: String,
    radius: String,
    bg: String,
    // 原生
    name: String,
    type: {
      type: String,
      default: 'text'
    },
    rows: Number,
    cols: Number,
    readonly: Boolean,
    disabled: Boolean,
    placeholder: String,
    filterValue: String
  },
  emits: ['update:modelValue', 'update:filterValue', 'blur'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    /**
     * 純UI Input
     */

    var cData = reactive({
      modelValue: props.modelValue
    });
    watch(function () {
      return props.modelValue;
    }, function () {
      cData.modelValue = props.modelValue;
    });
    watch(function () {
      return cData.modelValue;
    }, function () {
      emit('update:modelValue', cData.modelValue);
      emit('update:filterValue', cData.modelValue);
    });
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", {
        class: "u-input",
        style: _normalizeStyle({
          '--base-input-height': __props.h || null,
          '--base-input-radius': __props.radius || null,
          '--base-input-bg': __props.bg || null,
          '--base-input-font-size': __props.fz || null
        })
      }, [_ctx.$slots['prepend'] ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_renderSlot(_ctx.$slots, "prepend")])) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "default", {}, function () {
        return [(_openBlock(), _createBlock(_resolveDynamicComponent(__props.tag), _mergeProps({
          value: _unref(cData).modelValue,
          autocomplete: "off"
        }, _objectSpread({
          type: __props.type,
          name: __props.name,
          placeholder: __props.placeholder,
          readonly: __props.readonly,
          disabled: __props.disabled,
          rows: __props.rows
        }, __props.inputBind), {
          onInput: _cache[0] || (_cache[0] = function ($event) {
            return _unref(cData).modelValue = $event.target.value;
          }),
          onBlur: _cache[1] || (_cache[1] = function ($event) {
            return _ctx.$emit('blur');
          })
        }), null, 16, ["value"]))];
      }), _ctx.$slots['append'] ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_renderSlot(_ctx.$slots, "append")])) : _createCommentVNode("", true)], 4);
    };
  }
};