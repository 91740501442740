/* unplugin-vue-components disabled */import __unplugin_components_0 from 'D:/Projects/118_meijia/frontend/src/components/form/UCheckbox.vue';
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.function.name.js";
import { unref as _unref, mergeProps as _mergeProps, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { useField } from "vee-validate";
import { Checkbox } from '@/js/functions.js';
export default {
  __name: 'BaseCheckbox',
  props: {
    modelValue: {
      type: [String, Number, Array, Boolean],
      default: ''
    },
    value: {
      type: [String, Number, Boolean],
      default: ''
    },
    text: [String],
    name: {
      type: [String, Number],
      default: ''
    },
    required: Boolean,
    disabled: Boolean,
    // size
    size: String // size /

  },
  emits: ['update:modelValue'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;

    var _Checkbox = Checkbox(reactive({
      emit: emit,
      modelValue: computed(function () {
        return props.modelValue;
      }),
      value: computed(function () {
        return props.value;
      }),
      name: computed(function () {
        return props.name;
      }),
      required: computed(function () {
        return props.required;
      }),
      disabled: computed(function () {
        return props.disabled;
      })
    })),
        thismodelValue = _Checkbox.modelValue,
        thisValue = _Checkbox.value,
        thisName = _Checkbox.name,
        thisRequired = _Checkbox.required,
        thisDisabled = _Checkbox.disabled,
        isSelected = _Checkbox.isSelected,
        toggleCheck = _Checkbox.toggleCheck; // validate


    var _useField = useField(props.name),
        thisErrMsg = _useField.errorMessage,
        name = _useField.name,
        value = _useField.value,
        meta = _useField.meta,
        errors = _useField.errors,
        resetField = _useField.resetField,
        validate = _useField.validate,
        handleChange = _useField.handleChange,
        handleBlur = _useField.handleBlur,
        setValidationState = _useField.setValidationState,
        checked = _useField.checked;

    return function (_ctx, _cache) {
      var _component_UCheckbox = __unplugin_components_0;

      return _openBlock(), _createBlock(_component_UCheckbox, {
        class: "base-checkbox",
        checked: _unref(isSelected)
      }, _createSlots({
        label: _withCtx(function () {
          return [_renderSlot(_ctx.$slots, "default", {}, function () {
            return [_createTextVNode(_toDisplayString(__props.text), 1)];
          })];
        }),
        default: _withCtx(function () {
          return [_createElementVNode("input", _mergeProps({
            type: "checkbox"
          }, {
            name: _unref(thisName),
            disabled: _unref(thisDisabled),
            required: _unref(thisRequired),
            'true-value': true,
            'false-value': false
          }, {
            onChange: _cache[0] || (_cache[0] = function ($event) {
              return _unref(toggleCheck)();
            })
          }), null, 16)];
        }),
        _: 2
      }, [_unref(thisErrMsg) ? {
        name: "errorMsg",
        fn: _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_unref(thisErrMsg)), 1)];
        }),
        key: "0"
      } : undefined]), 1032, ["checked"]);
    };
  }
};